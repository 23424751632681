
.header {
  width: 100%;
  height: 13rem;
  background: linear-gradient(95deg, #405DC2 0%, #7B41D9 100%);
  font-family: 'Montserrat', sans-serif;
  position: relative;
}

.header-content {
  position: absolute;
  left: 5%;
  bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header-logo-text {
  display: inline;
  color: white;
}

.header-logo-main {
  font-size: 4.5rem;
  font-weight: 900;
  font-style: italic;
}

.header-logo-sub {
  font-size: 4.5rem;
  font-weight: 400;
}

.header-subtitle {
  color: white;
  font-size: 1.5rem;
  font-weight: 450;
  margin-top: 0rem;  /* You already have this */
}

.header-content,
.header-logo,
.header-logo-text,
.header-subtitle {
  margin: 0;
  padding: 0;
}

.header-login {
  position: absolute;
  right: 2%;
  top: 15%;
  background-color: white;
  padding: 0.6rem 3rem;
  border-radius: 2.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
  color: #7B42D9;
  font-family: 'Montserrat', sans-serif;
  border: none;
  outline: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); /* added the shadow */
}

/* By default, the icon should be hidden */
.header-login-icon {
  display: none;
}

@media (max-width: 1024px) {

  .header-login {
    display: none;
  }

  .header-login-text {
    display: none;
  }

  .header-login-icon {
    display: none;
  }
}

/* Tablet view */
@media (max-width: 800px) {
  .header-logo-main, .header-logo-sub {
    font-size: 3.2rem;
  }
  
  .header-subtitle {
    font-size: 1.1rem;
  }

  .header {
      height: 10rem;
  }

  .header-login {
    padding: 0.2rem 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none
  }

  .header-login-text {
    display: none;
  }

  .header-login-icon {
    display: none;
  }
}

/* Mobile view */
@media (max-width: 450px) {
  .header-logo-main, .header-logo-sub {
    font-size: 2rem;
  }

  .header {
    height: 7.5rem;
}
  
  .header-subtitle {
    font-size: 1rem;
  }

  .header-content {
    bottom: 0.8rem;
  }
} 

