.loggedInUpload {
    width: 100%;
    height: auto;
    background: white;
    display: flex;
    flex-direction: column;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.loggedInSectionRow {
    width: 100%;
    display: flex;
    align-items: center;
}

.loggedInWink {
    text-align: center;
    font-size: 160px;  /* Reduced from 180px */
    font-family: 'Courier';
    font-weight: 400;
    color: black;
    transform: rotate(20deg);
    margin-left: -40px;
}

.loggedInLastElement {
    width: 100%;
    margin-right: 2%;
    margin-left: 2%;
}

.loggedInUploadArea {
    text-align: center;
    border: 3.5px dotted black;
    border-radius: 20px;
    background: #F2F2F2;
    padding: 2.5%;  /* Reduced from 3% */
    margin-bottom: 8px;  /* Reduced from 10px */
    margin-left: 2%;
    margin-right: 2%;
}

.loggedInDragAndDrop {
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: black;
    margin-bottom: 8px;  /* Reduced from 10px */
}

.loggedInOr {
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 400;
    color: black;
    margin-bottom: 8px;  /* Reduced from 10px */
}

.loggedInBrowseButton {
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: white;
    background-color: black;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 18px;  /* Slightly reduced padding */
    max-width: 500px;
    margin: 0 auto;
}

.loggedInSupportedFiles {
    text-align: center;
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 400;
    color: black;
    margin-top: 4px;  /* Reduced from 5px */
}

.loggedInSupportedFiles.error {
    color: red;
    font-weight: 600;
}

.loggedInUploadArea.dragging {
    border: 2px dashed #000;
    background-color: #f3f3f3;
}

.dropdown-container {
    width: 100%;
    background: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    cursor: pointer;
    justify-content: flex-start;
}

.dropdown-item img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}

.dropdown-item.logout {
    color: #E36363;
}

/* Tablet */
@media (max-width: 1200px) {
    .loggedInDragAndDrop,
    .loggedInOr,
    .loggedInBrowseButton {
      font-size: 20px;
    }
  
    .loggedInRotatingText {
      font-size: 25px;
      width: 500px; /* Add a fixed width */
    }
  
    .loggedInWink {
      font-size: 120px;  /* Decreased from 180px */
    }
  
    .loggedInSupportedFiles {
      font-size: 16px;
    }
}
  
/* Tablet portrait */
@media (max-width: 800px) {
    .loggedInSectionRow {
      flex-direction: column;
    }
  
    .loggedInWink {
      display: none;  /* Hide the done element */
    }

    .loggedInRotatingText {
        display: none;
    }

    .loggedInUploadArea {
        margin-left: 3%;
        margin-right: 3%;
    }
  
    .loggedInDragAndDrop,
    .loggedInOr,
    .loggedInBrowseButton {
      font-size: 18px;
    }

    .loggedInSupportedFiles {
      font-size: 14px;
    }

    .loggedInLastElement {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
}

/* Mobile */
@media (max-width: 500px) {
    .loggedInDragAndDrop{
        font-size: 14px;
    }
    .loggedInBrowseButton {
      font-size: 14px;
      padding: 10px 10px;
    }

    .loggedInOr {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .loggedInDragAndDrop {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .loggedInSupportedFiles {
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 3%;
    }

    .loggedInUploadArea {
      margin-bottom: 5px;
    }

    .loggedInLastElement {
      margin-bottom: 0.5rem;
    }
}
