.cards-container1 {
  background-color: #EFEFEF;
  text-align: center;
  padding: 20px;
}

.card-container {
  margin-bottom: 5rem;
}


.buttons-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.button {
  width: 30%;
  padding: 13px 10px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.gray-button {
  background: #5A53CC ;
  color: white;
  font-size: 20px;
}

.selected {
  background: #7942D9; /* Darker shade for selected button */
}

.card-text {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}

.testimonial-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-box {
  background: #EBEBEB;
  border-radius: 20px;
  padding: 20px;
  text-align: left;
  width: 80%;
}

.testimonial-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}

.testimonial-name {
  color: black;
  font-size: 25px; /* Adjust size as needed */
  font-family: 'Montserrat', sans-serif;
  font-weight: 700; /* Adjust weight as needed */
  margin: 0;
}

.testimonial-content {
  color: black;
  font-size: 20px;
  font-family: 'Courier', sans-serif; /* Courier font for testimonial content */
  font-weight: 500;
  margin-top: 22px;
}

.card-title {
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 32px; /* Adjust as needed */
}

.card-content1 {
  width: 55%;
  margin: 0 auto;
  background-color: #FFFFFF;
  text-align: left;
  padding: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  border-radius: 2rem;
  overflow-x: hidden;
}

.card-content2 {
  width: 55%;
  margin: 0 auto;
  background-color: #FFFFFF;
  text-align: left;
  padding: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
  border-radius: 2rem;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
}

.text-bold, .text-medium, .text-regular {
  color: black;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  word-wrap: break-word;
}

.text-bold {
  font-weight: 700;
}

.text-medium {
  font-weight: 500;
}

.text-regular {
  font-weight: 400;
}

.cost-content {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  flex-direction: column;
  font-size: 20px;
}

.cost-row {
  /* Style for the first row */
  margin-bottom: 20px; /* Adjust as needed */
}

.cost-columns {
  display: flex;
  justify-content: space-between;
}

.dime-column {
  flex-basis: 30%; /* Adjust the percentage as needed */
  display: flex;
  justify-content: flex-start; /* Aligns the image to the left */
}

.text-column {
  flex-basis: 70%; /* Adjust the percentage as needed */
  padding-left: 30px; /* Optional, for some spacing between the image and text */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dime-image {
  width: 300px;
  height: 300px;
  transform: rotate(-35deg);
}

.lonesome-dime {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-decoration: underline;
}

.sub-text {
  margin-top: -30px;
}

.accaf {
  flex-direction: column;
  justify-content: center; 
  height: 90%; 
}

.narcaf {
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  height: 90%; 
}

/* active step */
._2Jtxm._35Ago ._2kL0S {
  background-color: #7942D9 !important;
}

/* completed step */
._2Jtxm._2ZUAI ._2JvrO {
  background-color: #5A53CC !important;
}

/* Initial state of the gradient (empty bar) */
._2Jtxm::before {
  background: linear-gradient(to left, #e7eaf3 100%, #5A53CC 0%) !important;
  background-size: 200% 100%!important;
  background-position: right bottom!important;
  transition: background-position 200ms ease-in-out!important;
}

/* Intermediate state of the gradient, when transitioning to the next step (filling up) */
/* The class name for the intermediate state must match the actual class applied by the progress bar */
._2Jtxm._2ZUAI::before, ._2Jtxm._35Ago::before {
  background: linear-gradient(to left, #5A53CC 0%, #5A53CC 100%)!important;
  background-position: left bottom!important;
}

/* Final state of the gradient, when it's fully filled (purple) */
._2Jtxm._1CcaK::before {
  background: linear-gradient(to left, #5A53CC 100%, #5A53CC 100%)!important;
}

/* When the user clicks 'prev', the gradient should animate from left to right (emptying out) */
._2Jtxm.regressing::before {
  background-position: right bottom!important;
  background: linear-gradient(to right, #e7eaf3 100%, #5A53CC 0%)!important;
}

._1hzhf {
  font-family: 'Montserrat';
}

/* Next button */
._hsN1w {
  background-color: #5A53CC !important;
  border: 1px solid #7942D9 !important;
  font-size: 20px;
  color: white;
  border-radius: 12px !important;
}

/* Prev button */
._3CDiP {
  font-size: 20px;
  border-radius: 12px !important;
}

.step-content {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  margin-top: 2rem;
  font-weight: 600;
  font-size: 25px;
}

.step-content img {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

._53Ji7 {
  padding: 0 !important;
}

/* Tablets: Less than 1200px */
@media (max-width: 1200px) {

  .card-title{
    font-size: 25px;
  }

  .card-content1 {
    padding: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .card-content2{
    padding: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .gray-button{
font-size: 18px;
  }

  .text-bold, .text-medium, .text-regular {
    font-size: 18px;
  }

  .buttons-row {
    margin-bottom: 15px;
  }

  .cost-content{
    font-size: 18px;
  }

  .card-text{
    font-size: 18px;
  }
  .dime-image {
    width: 200px;
    height: 200px;
  }

  .lonesome-dime {
    font-size: 3.5vw;
  }

  .testimonial-content{
    font-size: 16px;
  }

  .testimonial-name{
    font-size: 18px;
  }

  .testimonial-box {
    padding: 15px;
  }

  .lonesome-dime {
    font-size: 20px;
  }

  .sub-text {
    margin-top: -20px;
  }

  .step-content {
    font-size: 20px;
    margin-top: 1.5rem;
  }

  /* Next button */
._hsN1w {
  font-size: 16px;
}

/* Prev button */
._3CDiP {
  font-size: 16px;
}

/* Step text */
._1hzhf {
  font-size: 14px;
}

.contact{
  font-size: 18px;
}

.footer-email {
  font-size: 18px;
}

.message {
  font-size: 14px;
}

}

/* Tablets in Portrait Mode: Less than 800px */
@media (max-width: 800px) {

  .card-content1, .narcaf {
    line-height: 0.95 !important;
  }
  
  .card-title{
    font-size: 22px;
  }

  .card-content1 {
    padding: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .card-content2{
    padding: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .gray-button{
font-size: 12px;
padding: 1rem;
  }

  .text-bold, .text-medium, .text-regular {
    font-size: 14px;
  }

  .card-text{
    font-size: 14px;
  }
  .dime-image {
    width: 100px;
    height: 100px;
  }

  .testimonial-content{
    font-size: 14px;
    margin-top: 17px;
  }

  .testimonial-name{
    font-size: 16px;
  }

  .testimonial-box {
    padding: 15px;
    padding-right: 5px;
  }

  .lonesome-dime {
    font-size: 16px;
  }

  .cost-content{
    font-size: 14px;
  }

  .sub-text {
    margin-top: -15px;
  }

  .step-content {
    font-size: 16px;
    margin-top: 1rem;
  }

  /* Next button */
._hsN1w {
  font-size: 13px;
}

/* Prev button */
._3CDiP {
  font-size: 13px;
}

/* Step text */
._1hzhf {
  font-size: 11px;
}

.contact{
  font-size: 18px;
}

.footer-email {
  font-size: 18px;
}

.message {
  font-size: 14px;
}

}

/* Mobile Devices: Less than 500px */
@media (max-width: 600px) {

  .card-title{
    font-size: 18px;
  }

  .card-container{
    margin-bottom: 3rem;
  }

  .card-content1 {
    padding: 1.5rem;
    width: 80%;
  }

  .card-content2{
    padding: 1rem;
    padding-top: 1.5rem;
    width: 80%;
  }

  .gray-button{
font-size: 12px;
padding: 0.5rem;
  }

  .text-bold, .text-medium, .text-regular {
    font-size: 12px;
  }

  .card-text{
    font-size: 12px;
  }
  .dime-image {
    width: 100px;
    height: 100px;
  }

  .testimonial-content{
    font-size: 12px;
    margin-top: 10px;
  }

  .testimonial-name{
    font-size: 13px;
  }

  .testimonial-box {
    padding: 10px;
    padding-right: 5px;
  }

  .lonesome-dime {
    font-size: 13px;
  }

  .cost-content{
    font-size: 12px;
  }

  .sub-text {
    margin-top: -10px;
  }

  .step-content {
    font-size: 16px;
    margin-top: 0.5rem;
  }

  /* Next button */
._hsN1w {
  font-size: 13px;
}

/* Prev button */
._3CDiP {
  font-size: 13px;
}

/* Step text */
._1hzhf {
  font-size: 11px;
}

.contact{
  font-size: 15px;
}

.footer-email {
  font-size: 15px;
}

.message {
  font-size: 14px;
}
}

/* Initially hide the responsive warning card */
.responsive-screen-warning {
  display: none;
  background-color: #e9cc88; /* Light red background color for visibility */
  color: #000000; /* Dark text color for readability */
  padding: 20px; /* Adequate padding for content */
  margin: 20px 0; /* Spacing above and below the card */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  text-align: center; /* Center the text */
  margin: 0 auto;
  width: 60%;
}

.bold{
  font-weight: 600;
}

/* Show the warning card on screens smaller than 1024px */
@media (max-width: 1024px) {
  .responsive-screen-warning {
    display: block;
  }
}

/* Example: Assuming .homepage is a class you add to your <body> or a container div on the HomePage component */
.homepage .responsive-screen-warning {
  display: none; /* Ensures the warning doesn't show on the homepage */
}
