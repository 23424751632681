.signup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.signup-container {
    width: 450px; /* reduced width */
    height: 700px;  /* reduced height */
    background: #EEEEEE;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    padding: 15px;  /* reduced padding */
    box-sizing: border-box;
}

.signup-close {
    position: absolute;
    top: 2rem;
    right: 3rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
}

.signup-welcome-container {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.signup-welcome-text {
    color: black;
    font-size: 30px;  /* reduced font size */
    font-family: 'Montserrat';
    font-weight: 400;
}

.signup-brand-name {
    color: black;
    font-size: 35px;  /* reduced font size */
    font-family: 'Montserrat';
    font-weight: 700;
}

.signup-input-group {
    margin: 0.8rem 2.5rem;  /* reduced margin */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.8rem;  /* reduced margin-bottom */
}

.signup-name-label,
.signup-email-label,
.signup-password-label,
.signup-confirm-password-label {
    color: black;
    font-size: 16px;  /* reduced font size */
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0;
}

.signup-name-input,
.signup-email-input,
.signup-password-input,
.signup-confirm-password-input {
    height: 40px;  /* reduced height */
    background: #E6E6E6;
    border-radius: 15px;
    margin-top: 0.5rem;
    border: none;
    padding: 0 18px;  /* reduced padding */
    font-family: 'Montserrat';
    font-size: 15px;  /* reduced font size */
    width: 90%;
}

.signup-name-input:focus,
.signup-email-input:focus,
.signup-password-input:focus,
.signup-confirm-password-input:focus {
    outline: none;
}

.signup-button {
    width: 320px;  /* reduced width */
    height: 50px;  /* reduced height */
    background: #966DDA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    text-align: center;
    line-height: 50px;  /* adjusted line height */
    color: white;
    font-size: 20px;  /* reduced font size */
    font-family: 'Montserrat';
    font-weight: 700;
    cursor: pointer;
    margin-top: 0.5rem;  /* reduced margin-top */
}

.signup-login-prompt {
    color: black;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
}

.signup-bold-login {
    font-weight: 700;
}

.signup-button, .signup-google-login {
    user-select: none;
}

.signup-login-prompt {
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
}

.signup-google-login {
    width: 320px;  /* reduced width */
    height: 45px;  /* reduced height */
    background: #F9F9F9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    border-width: 0;
    display: flex;
    align-items: center;
    gap: 25px;  /* reduced gap */
    margin-bottom: 1.5rem;  /* reduced margin-bottom */
}

.signup-google-login img {
    width: 22px;  /* reduced width */
    height: 22px;  /* reduced height */
    margin-left: 28px;  /* adjusted margin-left */
}
  
.signup-google-login span {
    color: #0F0F0F;
    font-size: 17px;  /* reduced font size */
    font-family: 'Montserrat';
    font-weight: 700;
}

.signup-login-prompt:focus, .signup-google-login:focus {
    outline: none;
}

.signup-centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
  }

  .signup-separator {
    color: black;
    font-size: 15px;  /* reduced font size */
    font-family: 'Montserrat';
    font-weight: 700;
    margin-top: 0.7rem;  /* reduced margin-top */
    margin-bottom: 0.4rem;  /* reduced margin-bottom */
}

