.homePage {
    display: flex;
    flex-direction: column;
    flex: 1;  
  }

  .loggedInHomePage {
    display: flex;
    flex-direction: column;
    flex: 1;  
    background-color: #EFEFEF; 
    margin-top: 10vh;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);  /* semi-transparent black */
    z-index: 9999;  /* to ensure the overlay is on top of other elements */
  }
  
