.problems-solved-container {
    min-height: 96vh; /* Ensures it's never smaller than the viewport */
    width: 100%;
    background-color: #EFEFEF; /* Your desired background color */
    padding-bottom: 4vh; /* Adds space at the bottom */
}

.main-content {
    display: flex;
    align-items: start;
    justify-content: center;
    padding-top: 9vh;
}

body {
    margin: 0;
    background-color: #EFEFEF; /* Match the background color */
}

.problem-status-container {
    position: fixed; /* Keep the container fixed on the screen */
    top:11vh; /* Adjust this value so it's below your header */
    left: 2vh; /* Maintain a consistent margin from the left */
    width: 6.5%; /* Set a fixed width or as per your design */
    max-height: 87vh; /* Maximum height it can occupy without scrolling */
    overflow-y: auto; /* Enables scrolling for overflow content */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #FFFFFF; 
    border-radius: 15px;
    box-sizing: border-box; /* Includes padding in the container's dimensions */
}


.problems-solved-header {
    background-color: #7B41D9;
    padding-top: 1vh;
    padding-bottom: 1vh;
    height: 7vh;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed; /* Changed from relative to fixed */
    top: 0; /* Aligns the header at the top of the viewport */
    left: 0; /* Ensures the header starts from the left edge of the viewport */
    z-index: 1000; /* Ensures the header is above other content */
}

.problems-solved-header-text {
    color: white;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    margin-left: 0;
}

.problems-solved-header-text .problem-label {
    font-weight: 800; /* Bold */
}

.problems-solved-header-text .of-label {
    font-weight: normal; /* Not bold */
}

.problems-solved-header-text .total-number {
    font-style: italic; /* Italic */
}


.chevron-button, .home-button {
    background-color: white;
    border-radius: 15px;
    border: 0;
    padding: 8px;
    cursor: pointer;
    width: 50px;
}

.chevron-button-small {
    background-color: white;
    border-radius: 10px;
    border: 0;
    padding: 6px;
    cursor: pointer;
    width: 30px;
}


.home-button {
    border-radius: 50%; /* Makes the button circular */
    height: 5vh; /* Half the height of the header */
    width: 5vh; /* Keep width and height the same for a circle */
    position: absolute;
    left: 35px; /* 50px from the left */
}


.spacer {
    width: 30px; /* Adjust the width to control the space */
    margin: 0;
}

.content-container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin: auto;
    margin-top: 2vh;
    margin-left: 9%;
    margin-right: 2vh;
    border-radius: 15px;
    /* This ensures the container can grow with its content */
    /* Removed overflow-y to prevent it from becoming scrollable */
}


.column, .column-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto; /* Allows scrolling within each column */
    /* Adjust height or max-height based on your layout needs */
    min-height: 80vh; /* Ensures columns have a minimum height */
    padding: 10px;
    width: 100%; /* Adjust if necessary */
}

.column-left {
    flex: 0 0 30%; /* Adjusts the width of the left column */
    border-right: 1px dashed black; /* Visual separation */
}

.column {
    flex-grow: 1; /* Allows the right column to fill the remaining space */
}


.transcription-header, .solution-header {
    width: 100%; /* Full width of the column */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #8A67F3;
    border-radius: 10px;
    padding: 10px;
    height: 8vh;
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.confidence-container {
    width: 90%;
    background-color: #ddd; /* Light grey background */
    border-radius: 5px;
    overflow: hidden; /* Ensures the inner bar doesn't spill out */
    margin-left: auto; /* Centers the container horizontally */
    margin-right: auto; /* Centers the container horizontally */
  }
  
  .confidence-bar {
    height: 20px; /* Set the height of the bar */
    border-radius: 5px;
    display: flex;
    align-items: center; /* Centers the text vertically */
    justify-content: center; /* Centers the text horizontally */
    color: white; /* Text color */
    font-weight: bold;
  }
  
  .confidence-text {
    margin-left: 10px;
  }

  .transcription-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* Full width of its parent */
}

.info-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px; /* Space between the title and the button */
}

.added-info {
    width: 90%;
    background-color: #EFEFEF;
    border-radius: 10px;
    padding-bottom: 20px;
    margin-left: auto; /* Centers the container horizontally */
    margin-right: auto; /* Centers the container horizontally */
    margin-bottom: 2vh;
}

  
  
  

.transcription-container, .solution-container {
    width: 100%; /* Full width of the column */
    background: white; /* Your chosen color */
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 2vh;
    padding-bottom: 4vh;
}


.transcription-text {
    text-align: center; /* Centers the text */
    align-self: center;
    color: white;
    font-size: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    margin-left: 2vh;
    margin: 1vh;
    word-wrap: break-word;
}


.transcription-content, .solution-content {
    padding: 10px;
    width: 100%; /* Full width of the container */
    box-sizing: border-box;
    font-family: 'Courier New', Courier, monospace;
}

.solution-content{
    padding-top: 30px;
    height: 100%;
}

.image-container img, .mathpix-output div, .solution-content div {
    max-width: 100%; /* Ensures the content doesn't exceed its container */
    height: auto;
    overflow: hidden; /* Ensures content doesn't spill out */
    font-weight: 600;
}

.expand-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Fixed width */
    height: 40px; /* Fixed height */
    background: none;
    border: none;
    cursor: pointer;
}

.expanded-icon, .collapsed-icon {
    color: white;
    font-size: 30px;
    transition: transform 0.3s; /* Smooth transition for rotation */
}

.expanded-icon {
    transform: rotate(180deg); /* Rotate icon */
}

.counter {
    text-align: center; 
    align-self: center;
    color: white;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    margin-left: 2vh;
    margin: 1vh;
    word-wrap: break-word;
}

.retry-button {
    font-size: 1rem; /* Matching the font size of "Our Solution" text */
    color: white; /* White text color */
    background-color: #7B41D9; /* Purple background color */
    border-radius: 20px; /* Rounded edges */
    padding: 10px 20px; /* Padding for the button */
    border: none; /* Remove border */
    cursor: pointer; /* Pointer cursor on hover */
    font-weight: bold; /* Bold font weight */
}

.retry-button:disabled {
    background-color: #353436; /* Slightly lighter purple for disabled state */
    cursor: not-allowed; /* Not-allowed cursor on disabled state */
}