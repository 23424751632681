.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 3rem;
  align-items: center;
  background-color: black;
  color: white;
  margin-top: 5rem;
  height: 8rem;
  width:100%;
  overflow-y: hidden;
}

.footer-column-1 {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 4rem;
}

.footer-column-2 {
  display: flex;
  flex-direction: column;
  margin-left: auto; /* Pushes the column to the right */
  width: 25%;
  padding-right: 4rem;
}

.contact {
  margin-top: 0.5rem;
  color: white;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

.footer-email {
  margin-top: 0.5rem;
  color: white;
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-decoration: underline;
  word-wrap: break-word;
}

.message {
  color: white;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

@media (max-width: 600px) {
  .footer-column-1{
    padding-left: 2rem;
  }
  .footer-column-2{
    display: none;
  }
}
