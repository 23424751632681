.solved-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin-top: 3em;
  }
  
  .solved-item {
    display: flex;
    background: white;
    border-radius: 40px;
    overflow: hidden;
    padding: 1.5em;
    width: 60%; /* Adjusted width */
    height: 20%; /* New height property */
    min-height: 20%; /* Minimum height */
  max-height: 20%; /* Maximum height */
    cursor: pointer;
    align-items: stretch;
  }

  .solved-item-col1 {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Adjust content spacing */
    align-items: flex-start; /* Align items to the left */
    padding-left: 3em;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  
  .solved-item-date, .solved-item-time {
    text-align: left;
  }
  
  .solved-item-tokens {
    text-align: left;
    margin-top: auto; /* Push to bottom */
  }
   
  .solved-item-date { font-size: 24px; font-family: 'Montserrat'; }
  .solved-item-time, .solved-item-tokens { font-size: 16px; font-family: 'Montserrat';}


  .solved-item-col2 {
    height: 100%;
    width: 35%;
    display: flex;
    align-items: center; /* Center the image vertically */
    justify-content: center; /* Center the image horizontally */
    overflow: hidden; /* Hide any overflow */
    padding-right: 3em;
  }
  
  .solved-item-image {
    width: 100%; /* Ensure full width coverage */
    height: 275px; /* Ensure full height coverage */
    object-fit: cover; /* Cover the entire area, might get stretched */
    border-radius: 20px;
    border: 3px solid #EFEFEF;
    margin-left: 5px;
  }
  
  
  .solved-item-date, .solved-item-time, .solved-item-tokens {
    color: #413A3A;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  .solved-item-tokens { color: #E75A5A; }
  .solved-item-date { margin-bottom: 0.3em; }
  
  
  .no-solved-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* This height is needed to enable vertical centering */
  }
  
  .no-solved-message {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: center; /* Center the text horizontally */
  }
  