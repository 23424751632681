.tokens-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #EFEFEF;
    gap: 4rem;
    max-width: 100%;
}

.tokens-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    width: 100%;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    margin-top:2rem;
    margin-bottom: 0; 
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.tokens-header-container h2,
.tokens-header-container p {
    margin: 0; 
    padding: 0.3rem 0; 
}

.cards-container {
    display: flex;
    flex-direction: row;
    gap: 3rem; /* Increased gap for more space between cards */
    width: 100%;
    justify-content: center;
    align-items: center;
}

.token-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 40px;
    width: 20%;
    max-width: 300px;
    height: 270px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Shadow for pop-out effect */
    transform: scale(1.02); /* Subtle scale for all cards */
}

.token-icon {
    width: 100px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
  }

.token-card.large {
    max-width: 400px;
    width: 26%;
    height: 400px;
    padding: 1.6rem 1.3rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
    transform: scale(1.05);
}

.token-card img {
    width: 150px;
    height: 100px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.token-card.large img {
    width: 240px;
    height: 240px;
    margin-bottom: 10px;
}

.token-amount {
    font-family: 'Courier';
    font-size: 25px;
    text-align: center;
}

.token-price {
    display: none;
}

.token-amount {
    margin-bottom: 0px;
    margin-top: 10px;
}


.token-card.large .token-amount, .token-card.large {
    font-size: 30px;
}

.buy-button {
    background: black;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 600;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.buy-button:hover {
    background: #333;
}

.buy-button {
    background: black;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    font-family: 'Montserrat';
    font-weight: 600;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px; /* Fixed width */
    height: 60px; /* Fixed height */
}

.token-card.large .buy-button {
    padding: 0.65rem 1.3rem;
    font-size: 28px;
    width: 250px; /* Fixed width for large card */
    height: 80px; /* Fixed height for large card */
}

.code-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Adjust this value to control the spacing between the input section and the message */
    width: 100%; /* Ensure it takes the full available width */
    max-width: 400px; /* Match this with the max-width of your input section for consistent alignment */
    margin: 0 auto; /* Center the code area if needed */
    margin-bottom: 60px;
  }

.code-input-section {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 20px; /* Rounded edges */
    padding: 10px 0%; /* Padding inside the rectangle */
    margin-top: 2rem; /* Space from the content above */
    width: 60%; /* Adjust based on your layout */
    max-width: 400px; /* Maximum width */
  }
  
  .code-input {
    margin-left: 10px;
    width: 80%;
    border: 1px solid #ccc; /* Border color */
    border-radius: 10px; /* Rounded edges for the input field */
    padding: 5px 10px; /* Padding inside the input field */
    font-size: 1rem; /* Font size for the input */
    color: grey; /* Color for the placeholder text */
    margin-right: 10px;
  }
  
  .submit-code-button {
    width: 40px;
    height: 40px; /* Fixed height to align with the input field */
    background: black; /* Button background color */
    color: white; /* Button text color */
    border-radius: 50%; /* Circular shape */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor on hover */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem; /* Adjust the font size for the arrow */
    margin-right: 10px;
  }

  .message {
    color: transparent; /* Initially transparent */
    transition: color 0.5s ease; /* Smooth transition for fading */
    text-align: center;
    font-size: 1rem; /* Adjust as needed */
    margin-bottom: 10px;
  }
  
  .success {
    color: green; /* Green text for success */
  }
  
  .error {
    color: red; /* Red text for errors */
  }
  