.upload {
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    margin-bottom: 2%;
}

.sectionRow {
    width: 100%;
    display: flex;
    align-items: center;
}

.done {
    text-align: center;
    font-size: 180px;
    font-family: 'Courier';
    font-weight: 400;
    color: black;
    transform: rotate(20deg);
    margin-left: -20px;
}

.rotatingText {
  /* Common styles */
  text-align: center;
  font-size: 30px;
  font-family: 'Courier';
  font-weight: 400;
  color: black;
  margin-left: 40px;
  transition: all 0.1s ease-in-out;
  width: 800px; /* Add a fixed width */
  overflow: hidden; /* Hide the overflow */
  text-overflow: ellipsis; /* Add an ellipsis when the text overflows */
}



.rotatingText.entering {
  opacity: 0;
  transform: translateY(30%);
}

.rotatingText.showing {
  opacity: 1;
  transform: translateY(0);
}

.rotatingText.exiting {
  opacity: 0;
  transform: translateY(-30%);
}

.lastElement {
    width: 100%;
    margin-right: 2%;
    margin-left: 2%;
    margin-top:1%;
    margin-bottom:1%;
}

.uploadArea {
    text-align: center;
    border: 3.5px dotted black;
    border-radius: 20px;
    background: #F2F2F2;
    padding:5%;
    margin-bottom: 10px;
}

.DragAndDrop {
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: black;
    margin-bottom: 20px;
}

.or {
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 400;
    color: black;
    margin-bottom: 20px;
}

.BrowseButton {
    font-size: 30px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: white;
    background-color: black;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 13px 20px;  /* Adjusted from 13px 80px */
    max-width: 500px;  /* Optional: set a maximum width */
    margin: 0 auto;  /* Center the button if you like */
}


.supportedFiles {
    text-align: center;
    font-size: 20px;
    font-family: 'Montserrat';
    font-weight: 400;
    color: black;
    margin-top: 10px;
}

.supportedFiles.error {
  color: red;
  font-weight: 600;
}

.uploadArea.dragging {
  /* Styles for when the user is dragging a file over the drop zone */
  border: 2px dashed #000;
  background-color: #f3f3f3;
}

/* Tablet */
@media (max-width: 1200px) {
    .DragAndDrop,
    .or,
    .BrowseButton {
      font-size: 20px;
    }
  
    .rotatingText {
      font-size: 25px;
      width: 500px; /* Add a fixed width */
    }
  
    .done {
      font-size: 120px;  /* Decreased from 180px */
    }
  
    .supportedFiles {
      font-size: 16px;
    }
  }

  @media (max-width: 1024px) {
    .upload {
      display: none;
    }

    .done {
      display: none;  /* hide the done element */
    }
  }
  
  /* Tablet portrait */
  @media (max-width: 800px) {
    .upload {
      display: none;
    }
    .sectionRow {
      flex-direction: column;
    }
  
    .done {
      display: none;  /* hide the done element */
    }

    .rotatingText{
        display: none;
    }

    .uploadArea {
        margin-left: 3%;
        margin-right: 3%;
    }
  
    .DragAndDrop,
    .or,
    .BrowseButton {
      font-size: 18px;
    }
  
    .rotatingText {
      font-size: 20px;
      order: -1;  /* This will make it appear above */
      margin-bottom: 2%;
    }
  
    .supportedFiles {
      font-size: 14px;
    }
    .lastElement{
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  

   /* Mobile */
   @media (max-width: 500px) {
    .BrowseButton {
      font-size: 14px;
      padding: 10px 10px;
    }
    .or {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .DragAndDrop{
      font-size: 14px;
      margin-bottom: 10px;
    }
    .supportedFiles{
      font-size: 12px;
      margin-top: 0px;
      margin-bottom: 3%;
    }
    .uploadArea{
      margin-bottom: 5px;
    }
    .lastElement{
      margin-bottom: 0.5rem;
    }
  }