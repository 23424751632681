.loggedin-header {
    box-sizing: border-box;
    width: 100%;
    height: 10vh;  /* use viewport height to set to 10% of the window's height */
    background: linear-gradient(95deg, #405DC2 0%, #7B41D9 100%);
    font-family: 'Montserrat', sans-serif;
    position: fixed; /* This will keep the header fixed at the top */
    top: 0; /* Anchors the header to the top */
    left: 0; /* Anchors the header to the left side */
    right: 0; /* Anchors the header to the right side */
    z-index: 10; /* Ensures that the header stays on top of other elements */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
}

.loggedin-header-content {
    display: flex;
    align-items: center;
    margin-top: 0.5rem; /* Add some margin to lower the text */
}

.loggedin-greeting {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-size: 2rem;
    font-weight: 900; /* Bolded */
    margin-right: 0.75rem; /* Add space after the comma */
}

.loggedin-username {
    color: white;
    font-size: 2rem;
    font-weight: 500; /* Adjusted weight */
    font-style: italic;
}

.loggedin-header-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center; /* To ensure vertical alignment */
}


.loggedin-button {
    background: transparent; /* Make button background transparent */
    border: none;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    margin-right: 1.25rem;
    font-size: 20px;
    font-weight: 400; /* Regular weight by default */
    color: white; /* Default color is white */
    transition: font-weight 0.3s; /* Smooth transition for font weight changes */
}

.loggedin-button::after {
    content: ''; /* Essential for the pseudo-element to show */
    display: block;
    position: absolute;
    bottom: -5px; /* This determines how far down the underline appears, adjust as needed */
    left: 0;
    width: 100%;
    height: 1px; /* Height of the underline */
    background-color: white; /* Color of the underline */
    transform: scaleX(0); /* Initially scale the underline to 0 width */
    transition: transform 0.3s; /* Smooth transition for underline width changes */
}

.loggedin-button:hover::after {
    transform: scaleX(1); /* On hover, expand the underline to full width */
}

.loggedin-button.active::after {
    transform: scaleX(1); /* Make sure the underline is full width when the button is active */
}

.loggedin-button.active {
    font-weight: 900; /* Bold the text when it's clicked */
}

.loggedin-profile {
    width: 40px;  
    height: 40px;  
    border-radius: 50%; /* Makes the image circular */
    cursor: pointer; /* Changes the cursor to a hand when hovering over the image */
    overflow: hidden; /* To make sure the image fits within the circle */
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-width: 0px;
    margin-left: 1.25rem;
    box-sizing: border-box;
}

.dropdown-container {
    position: absolute;
    z-index: 11; /* Ensure dropdown is above other elements */
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px; /* Optional rounded corners */
    overflow: hidden; /* Hide overflow content */
    max-width: 20%;
}

.dropdown-item {
    flex-direction: row;
}

.dropdown-item-text {
    font-size: 18px;
    margin: 0;
}

.dropdown-item:first-child {
    border-bottom: 2px solid black;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.dropdown-item.logout .dropdown-item-text {
    color: #E33333; /* or any other color you desire */
}