.EmailGenerator {
  text-align: center; /* Centers the content horizontally */
  padding-top: 50px; /* Adds spacing at the top */
}

.EmailGenerator-div {
  margin: 15px; /* Adds spacing around divs that contain input fields */
  text-align: left; /* Aligns the text to the left */
}

.EmailGenerator-label {
  display: block; /* Makes label elements block to fill the width */
  margin-bottom: 5px; /* Adds a little space below the label */
  font-size: 1.1em; /* Slightly larger font size for labels */
  color: #333; /* Dark color for better readability */
  text-align: center; /* Aligns the text to the left */
}

.EmailGenerator-input {
  display: block; /* Makes input elements block to fill the width */
  width: 80%; /* Set a max-width to maintain usability */
  margin: 0 auto 20px; /* Adds vertical space below elements and auto centers them */
  padding: 10px; /* Adds padding inside the input fields */
  border-radius: 15px; /* Rounds the corners of the input fields */
  border: 1px solid #ccc; /* Adds a light grey border to the input fields */
}

.EmailGenerator-button {
  display: block; /* Ensures the button is a block element */
  width: 30%; /* Width of the button */
  padding: 10px 20px; /* Padding inside the button */
  margin: 20px auto; /* Centers the button horizontally and adds space */
  border-radius: 15px; /* Rounds the corners of the button */
  background-color: #007BFF; /* Background color */
  color: white; /* Text color */
  border: none; /* Removes border */
  cursor: pointer; /* Changes the cursor to a pointer on hover */
  font-size: 16px; /* Increases font size for better readability */
}

.EmailGenerator-button:hover {
  background-color: #0056b3; /* Darkens the button on hover for a visual effect */
}

.EmailGenerator-viewDraftButton {
  background-color: #28a745; /* Green background for View Draft button */
}

.EmailGenerator-editButton {
  background-color: #d3d3d3; /* Light gray background for Edit button */
}

.EmailGenerator-buttons {
  display: flex;
  justify-content: center;
}

.EmailGenerator-link {
  color: #007bff; /* Sets the color of links to match the button */
  text-decoration: none; /* Removes underline from links */
}

.EmailGenerator-link:hover {
  text-decoration: underline; /* Adds underline on hover for links */
}

.EmailGenerator-error {
  color: red;
}
