.responsive-warning-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; /* Cover the full width of the viewport */
    height: 100vh; /* Cover the full height of the viewport */
    z-index: 1000; /* Ensure it's above other content */
    background-color: rgba(0, 0, 0, 1); /* Fully opaque black background */
    color: white;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px;
    text-align: center;
}

.main-message {
    font-size: 2rem; /* Largest font size for the main message */
    margin-bottom: 10px;
}

.secondary-message {
    font-size: 1.75rem; /* Slightly smaller font size for the secondary message */
    margin-top: 0px; /* Add some space between the main and secondary messages */
    font-weight: 700; /* Bold for emphasis */
}

.small-text {
    font-size: 1rem; /* Keep the small text size as is for the note */
    margin-top: 20px; /* Additional space above the note */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .main-message {
        font-size: 2rem; /* Adjusted font size for smaller screens */
    }

    .secondary-message {
        font-size: 1.5rem; /* Adjusted font size for smaller screens */
    }

    .small-text {
        font-size: 0.85rem; /* Adjusted font size for smaller screens */
    }
}

/* Responsive adjustments for small devices like phones */
@media (max-width: 400px) {
    .main-message {
        font-size: 1.5rem; /* Adjusted for readability */
        margin-right: 1rem;
    }

    .secondary-message {
        font-size: 1.1rem; /* Adjusted for readability */
    }

    .small-text {
        font-size: 0.8rem; /* Adjusted for readability */
        padding: 0 20px; /* Adds padding to ensure text doesn't stretch to the edges */
        max-width: 90%; /* Ensures the text container doesn't span the full width */
        line-height: 1.2; /* Adjusts the line height for better readability */
    }
}
