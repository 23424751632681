.drafts-container {
    background: #FFFFFF;
    overflow: auto;
    white-space: nowrap;
    padding-left: 10%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative; 
  }

  .loading-container {
    height: 250px;  
    margin-top: 6%;
    margin-bottom: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .draft-item {
    display: inline-block;
    background: #F2F2F2;
    border-radius: 30px;
    margin: 1%;
    margin-top: 5%;
    margin-bottom: 5%;
    width: calc(23%);
    border: none;
  cursor: pointer;
  position: relative;
  }
  
  .draft-item-info {
    text-align: left; /* Aligned to the left as per requirement */
    padding-left: 10%; /* Added some padding for visual space */
    color: #413A3A;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
  
  
  .draft-item-info.date {
    font-size: 1.2rem;
    margin-top: 1%;
    font-weight: 500;
  }
  
  .draft-item-info.files {
    font-size: 1.5rem;
    margin-top: 10%;
  }
  
  .draft-item-preview {
    background: #D9D9D9;
    border-radius: 20px;
    margin: 5% auto;
    width: 80%;
    height: 250px;  /* You can adjust this value */
    position: relative;
  }

  .draft-item-preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;  /* Same as .draft-item-preview */
  }

  .remove-button {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;           /* Width of the button */
    height: 30px;          /* Height of the button (equal to the width to make it a circle) */
    background-color: #415DC3;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 25px;
    border-radius: 50%;    /* Makes the button a perfect circle */
    display: flex;         /* These two lines center the dash inside the button */
    align-items: center;
    justify-content: center;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content .modal-text {
    font-family: 'Montserrat', sans-serif;
    white-space: normal;
    font-size: 1.5rem;  
    font-weight: 700;
    overflow-wrap: break-word;  
    word-break: break-word;     
    max-width: 100%;            
    margin: 0;  /* Resets default margin of p tag */
}


.modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the text */
    gap: 10px;
    width: 20vw; /* Width set to 20% of viewport width */
    height: 25vh; /* Height set to 40% of viewport height */
    justify-content: space-around; /* To spread out content evenly */
}

/* Styles for the button group and individual buttons */
.button-group {
    display: flex;                   /* Align buttons in a row */
    justify-content: space-between; /* Ensures a bit of space between the buttons */
    width: 100%;                    /* Take up the entire width of its parent (.modal-content) */
}

.draft-delete-nah, 
.draft-delete-confirmation {
    width: 45%;                     /* Each button takes up 40% of .modal-content's width */
    box-sizing: border-box;         /* Ensures padding and border are included in the 40% width */
    padding: 20px 0px;             /* Padding inside the button for better appearance */
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border: none;
    border-radius: 20px;            /* Rounded corners for the button */
    cursor: pointer;
    text-align: center;             /* Centers the text inside the button */
    color: white;                   /* Text color inside the button */
}

.draft-delete-nah {
    background-color: grey;         /* Grey background for the "No, never mind" button */
}

.draft-delete-confirmation {
    background-color: #E33333;          /* Red background for the "Yes, I'm sure" button */
}

.nothing-here-background {
    width: 100%; /* Takes up 100% of the parent's width */
    height: 100%; /* Takes up the remaining height */
    background-color: white; /* White background color */
    display: flex; /* Using flex to center the texts vertically */
    flex-direction: column; /* Stacks the children (p tags) vertically */
    justify-content: center; /* Centers the children vertically */
    align-items: center; /* Centers the children horizontally */
    padding-top: 15vh;
    padding-bottom: 15vh;
}

.nothing-here {
    font-family: 'Montserrat', sans-serif; /* Sets font-family to Montserrat */
    font-size: 1.3rem; /* Decent size in rem units. Adjust as needed */
    text-align: center; /* Centers the text within each p tag */
    font-weight: 500;
    margin-bottom: 0.3rem; /* Margin at the bottom to push the next p tag a bit lower */
}

.nothing-here-secondary {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.75rem;
    font-weight: 600; /* Makes the text bold with a font-weight of 800 */
    margin-top: 0.3rem; /* Margin at the top to push this p tag a bit lower from the above p tag */
}

.emoji-bottom-right {
    position: absolute; /* Position it relative to its closest positioned ancestor */
    bottom: -10px; /* Place it 10px from the bottom */
    right: -50px; /* Place it 10px from the right */
    font-size: 160px; /* Set the font size */
    transform: rotate(-15deg); /* Rotate the emoji */
    z-index: 1; /* Ensure it's above other elements in the stack order */
}
