.login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .login-container {
    width: 500px;  /* Decreased width */
    height: 700px;  /* Increased height */
    background: #EEEEEE;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
  }
  
  
  .login-close {
    position: absolute;
    top: 2rem;
    right: 3rem;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }

.welcome-container {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .welcome-text {
    color: black;
    font-size: 35px;
    font-family: 'Montserrat';
    font-weight: 400;
  }
  
  .brand-name {
    color: black;
    font-size: 45px;
    font-family: 'Montserrat';
    font-weight: 700;
  }

  .input-group {
    margin: 1rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }
  
  .email-label,
  .password-label {
    color: black;
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0;
  }
  
  .email-input,
.password-input {
    height: 55px;
    background: #E6E6E6;
    border-radius: 15px;
    margin-top: 0.5rem;
    border: none;
    padding: 0 20px; /* Add some padding for the text inside the input */
    font-family: 'Montserrat';
    font-size: 16px;
    width: 90%;
}

.email-input:focus,
.password-input:focus {
    outline: none; /* Remove focus outline */
}

  
  .forgot-password {
    color: black;
    font-size: 14px;
    font-family: 'Montserrat';
    font-weight: 500;
    align-self: center;
    margin-top: 10px;
  }
  
  .login-button {
    width: 350px;
    height: 55px;
    background: #966DDA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    text-align: center;
    line-height: 55px;
    color: white;
    font-size: 22px;
    font-family: 'Montserrat';
    font-weight: 700;
    cursor: pointer;
    margin-top: 0.6rem;
  }

  .separator {
    color: black;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 700;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
  }
  
  .google-login {
    width: 350px;
    height: 50px;
    background: #F9F9F9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 2rem;
  }
  
  .google-login img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
  
  .google-login span {
    color: #0F0F0F;
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 700;
  }

  .signup-prompt {
    color: black;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
  }

  .bold-signup {
    font-weight: 700;
}

.login-button, .google-login {
    user-select: none;
}

.forgot-password, .signup-prompt {
    background: none;
    border: none;
    cursor: pointer;
    text-align: left; /* To keep it left-aligned */
}

.google-login {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Remove focus styles for a cleaner look */
.forgot-password:focus, .signup-prompt:focus, .google-login:focus {
    outline: none;
}
