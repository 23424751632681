body, html {
    height: 100%;
    margin: 0;
}

.main-container {
    display: flex;
    overflow: hidden;
}

.problem-selection-container {
    width: 100%;
    height: 100%;
    background: white;
}

.problem-selection-container.shrunk {
    width: 70%;
}

.cart-container {
    width: 30%;
    background: #7B41D9;
    position: relative;
    padding: 1.5rem 0;
}

.cart-header {
    position: relative;
    display: flex;
    height: 50px; 
    align-items: center;
    background: rgba(217, 217, 217, 0.07);
    padding: 10px 20px;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: white;
}

.cart-title {
    margin-right: 10px;
}

.cart-items-count {
    background: rgba(255, 255, 255, 0.35);
    border-radius: 6px;
    padding: 5px 10px;
    margin-right: 10px;
    font-size: 25px;
    font-weight: 600;
}

.cart-right-button-container {
    position: absolute;  /* Absolute positioning */
    z-index: 10;
}

.cart-close-btn {
    background: rgba(255, 255, 255, 0.15);
    width: 10vh;
    height: 10vh;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -2.5rem;
}

.cart-close-btn.selected {
    background: rgba(84, 41, 154, 1);
}

.cart-notification-bubble {
    position: absolute;
    top: 10%;
    right: 10%;
    background: #EF7575;
    border-radius: 50%;
    top: 5%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-notification-text {
    color: white;
    font-size: 0.8rem;
    font-weight: 900;
}

.cart-list {
    list-style: none;
    padding: 0 2rem;
    margin: 0;
    margin-top: 20px;
    max-height: calc(90vh - 150px); /* Adjust this value based on your header's height and any other space you want to exclude */
    overflow-y: auto;
}


.cart-item {
    display: flex;
    align-items: center;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 10px;
    position: relative;
    margin-left: 20px; /* Add horizontal padding to the left */
    margin-right: 20px; /* Add horizontal padding to the right */
    padding: 10px;
}

.cart-item-image-spinner {
    width: 90px;
    border-radius: 5px;
    border: 1px solid black;
    margin-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cart-item-image img {
    width: 90px;
    height: auto;
    border-radius: 5px;
    border: 1px solid black;
    margin-right: 10px;
}

.cart-item-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
   
    min-height: 90px; /* Setting a minimum height */
}

.problem-number,
.problem-page,
.problem-cost {
    text-align: left;
}
.problem-number {
    position: absolute;
    top: 10px;
}

.problem-page {
    position: absolute;
    top: 32px; /* You can adjust this value as needed. For example, if the height of .problem-number is 20px, setting this to 30px will position .problem-page right below it. */
}

.problem-cost {
    position: absolute;
    bottom: 10px;
}


.problem-number {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: black;
}

.problem-page {
    font-size: 18px;
    font-family: 'Courier', sans-serif;
    font-weight: 400;
    color: black;
}

.problem-cost {
    font-size: 16px;
    font-family: 'Courier', sans-serif;
    font-weight: 400;
    color: black;
    display: flex;
    align-items: center; /* Optional: to vertically align items in the middle */
}

.price {
    font-weight: 800; /* Bold */
    margin-right: 10px;
}


.cart-item-remove {
    z-index: 10;
    position: absolute;
    top: -7px;
    right: -7px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}



.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 12vh;
    background: #7B41D9;
    position: relative;
    padding-left: 1rem; /* Adjust as needed */
}

.header-text {
    color: white;
    font-size: 2rem;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
    margin-left: 1rem;
}

.back-and-title-container {
    display: flex;
    align-items: center;
}

.back-button {
    background: rgba(255, 255, 255, 0.15);
    border: none;
    outline: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 6.27vh;
    height: 6.27vh;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}


.middle-buttons-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 1rem;
}

.button-select, 
.shopping-cart-button {
    border: none;
    outline: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.button-select {
    background: rgba(255, 255, 255, 0.15);
    width: 6.27vh;
    height: 6.27vh;
}

.button-select.selected,
.shopping-cart-button.selected {
    background: rgba(1, 1, 1, 0.4);
}

.button-select-all {
    background: rgba(1, 1, 1, 0.42);
}

.right-button-container {
    position: relative;
    margin-right: 1rem;
}

.shopping-cart-button {
    background: rgba(255, 255, 255, 0.15);
    width: 8.25vh;
    height: 8.25vh;
    margin-right: 1rem;
}

.notification-bubble {
    position: absolute;
    top: 10%;
    right: 10%;
    background: #EF7575;
    border-radius: 50%;
    width: 2.2vh;
    height: 2.2vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-text {
    color: white;
    font-size: 0.8rem;
    font-weight: 900;
}

.carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 88vh;
}

.carousel-items {
    display: flex;
    overflow-x: hidden;
    width: 100%;
    justify-content: center;
    height: calc(100% - 80px);
}

.carousel-item {
    flex: 0 0 auto;
    position: relative;
    /* overflow: hidden; */
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    max-height: 85vh;
    
}

.carousel-item.focused {
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-item.prev,
.carousel-item.next {
    display: none;
}


.carousel-item.prev {
    left: 5%;
}

.carousel-item.next {
    right: 5%;
}

.carousel-item img {
    max-height: 100%;
    max-width: 100%;
    display: block;
    object-fit: contain;
}
.carousel-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.carousel-button, .carousel-index {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adjust as needed */
    transition: background-color 0.2s ease; /* Smooth transition for color change */
}

.carousel-button {
    width: 40px;  /* adjust as per your needs */
    height: 30px; /* adjust as per your needs */
    background: #A382D9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.carousel-button:active {
    background-color: #7B41D9; /* Darker color when button is pressed */
}

.carousel-button span {
    color: white;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 1000;
    word-wrap: break-word;
}

.carousel-index {
    width: 80px;  /* adjust as per your needs */
    height: 40px; /* adjust as per your needs */
    background: #7B41D9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-index span {
    color: white;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    word-wrap: break-word;
}



.noselect {
    -webkit-touch-callout: none;  /* iOS Safari */
    -webkit-user-select: none;    /* Safari */
    -khtml-user-select: none;     /* Konqueror HTML */
    -moz-user-select: none;       /* Old versions of Firefox */
    -ms-user-select: none;        /* Internet Explorer/Edge */
    user-select: none;            /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  }

  .remove-selection-btn {
    z-index: 10;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

  
.selection-label {
    position: absolute;
    top: -30px;
    left: 0;
    background-color: #7B41D9;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    z-index: 15; /* To ensure it's above other elements */
    pointer-events: none; /* So it doesn't interfere with other interactions */
}

.cancel-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 40px; /* Increase padding for a bigger popup */
    background-color: rgba(46, 50, 62, 0.8); /* Add semi-transparency using rgba */
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 400;
    border-radius: 5px;
    z-index: 1000;
    text-align: center; /* Horizontally center the contents */
}

.cancel-popup .cancel-icon {
    font-size: 50px; /* Adjust as needed for the size of the ❌ */
    margin-bottom: 10px; /* Spacing between the ❌ and the "Canceled" text */
}

.cancel-popup.show {
    display: block;
}

.not-big-enough-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 40px;
    background-color: rgba(46, 50, 62, 0.8);
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 400;
    border-radius: 5px;
    z-index: 1000;
    text-align: center;
}

.not-big-enough-popup .cancel-icon {
    font-size: 50px;
    margin-bottom: 10px;
}

.not-big-enough-popup.show {
    display: block;
}

.not-big-enough-popup.fade-out {
    animation: fadeOut 0.5s forwards;
}


@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Apply the animation when this class is added */
.cancel-popup.fade-out {
    animation: fadeOut 0.5s forwards;
}
.button-container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px; /* adjust spacing between buttons as needed */
    width: max-content;  /* this will ensure the container fits the content */
}

.button-label {
    font-size: 12px;
    margin-top: 5px;
    color: white;
    text-align: center;  /* ensure the text is centered */
}

.cart-footer {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
}

.pay-button-container {
    z-index: 1;
    display: flex;
    width: 80%;
    margin: 0 auto;
}

.or-text {
    font-size: 20px; 
    color: white;
    font-family: 'Montserrat', sans-serif;  
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
}

.use-tokens-btn {
    flex: 1;
    z-index: 11;
    padding: 10px 20px;
    font-size: 20px;  
    cursor: pointer;
    border-radius: 10px;
    background-color: #AE8BE7;
    border: 1px solid white;
    color: white;
    font-family: 'Montserrat', sans-serif;  
    font-weight: 800;
    outline: none;
    transition: background-color 0.3s;
}

.use-tokens-btn:hover, .pay-per-problem-btn:hover {
    background-color: #7133b2;  
}


.pay-per-problem-btn {
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    padding: 10px 20px;
    font-size: 16px;  
    cursor: pointer;
    border-radius: 10px;
    background-color: #AE8BE7;
    border: 1px solid white;
    color: white;
    font-family: 'Montserrat', sans-serif;  
    font-weight: 800;
    outline: none;
    transition: background-color 0.3s;
}

.subtotal-container {
    display: flex;
    align-items: center;
}

.subtotal-amount {
    font-size: 30px;
}

.subtotal-text {
    font-size: 14px;
    font-weight: 500; /* Thin */
    font-family: 'Montserrat', sans-serif;
    margin-left: 10px;
}


.cart-empty {
    text-align: center;
    padding: 20px;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
  
  .cart-empty .emoji {
    position: absolute;
    bottom: 5%;
    right: -25%;
    font-size: 150px; /* Adjust size as needed */
    transform: translateX(-50%) rotate(-30deg);
  }
  
  .cart-empty .sentence-1 {
    font-weight: 500;
    position: absolute;
    font-size: 20px; /* Adjust size as needed */
    bottom: 50%;
    right: 10%;
    width: 80%;
  }
  
  .cart-empty .sentence-2 {
    font-weight: 700;
    position: absolute;
    width: 80%;
    font-size: 20px; /* Adjust size as needed */
    right: 10%;
    bottom: 40%;
  }

  .service-fee-item {
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500; /* Default font weight for the whole item */
}

.service-fee-details {
    flex-grow: 1;
    text-align: center;
}

.service-fee-label {
    font-weight: 500;
    margin-right: 5px;
}

.service-fee-amount {
    font-weight: 700;
}

.anonymous-btn {
    width: 80%;
    margin: 0 auto;
    padding: 30px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    background-color: #AE8BE7;
    border: 1px solid white;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    outline: none;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.anonymous-btn:hover {
    background-color: #7133b2;
}

.anonymous-message {
    font-size: 20px;
    font-weight: 800;
}

.anonymous-subtext {
    font-size: 16px;
    font-weight: 550;
}

.pay-per-problem-btn.disabled {
    background-color: #AE8BE7; /* or a more greyed out color if you prefer */
    color: #cccccc; /* a lighter color indicating the button is not active */
    cursor: not-allowed; /* changes cursor to indicate the button is disabled */
    pointer-events: none; /* ensures that the button does not react to clicks */
    border: 1px solid #dddddd; /* lighter border color */
    opacity: 0.5; /* make the button semi-transparent */
}

.pay-per-problem-btn.disabled:hover {
    background-color: #AE8BE7; /* keep the same color as the normal disabled state */
}

/* Optional: Tooltip styles */
.tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.pay-per-problem-btn.disabled:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 1300px) {
    .cart-item-details,
    .problem-info,
    .problem-number,
    .problem-page,
    .problem-cost {
        font-size: 90%; /* Decreases the font size by 30% */
    }

    .header-text{
        font-size: 1rem;
    }

    .cart-title{
        font-size: 1.25rem;
    }

    /* Adjust the font size of other elements within the cart as needed */
    .cart-item-remove {
        width: 14px; /* Adjust the size of the remove button */
        height: 14px;
    }

    /* You can add more specific selectors to adjust other elements within the cart as needed */
}


/* Media query for screens with a width of less than 1300 pixels */
@media (max-width: 1300px) {
    .pay-per-problem-btn,
    .use-tokens-btn {
        padding: 8px 16px; /* Slightly reduce padding */
        font-size: 12px; /* Reduce font size for smaller screens */
        width: 45%; /* Adjust the width to fit your design. Consider using percentage for responsive adjustments */
    }

    .or-text {
        font-size: 18px; /* Optionally reduce the font size of the 'or' text */
    }

    .pay-button-container {
        align-items: center; /* Center buttons if container is flex */
        gap: 10px; /* Add some space between stacked buttons */
    }
    .subtotal-amount {
        font-size: 12px; /* Decrease the font size */
    }

    .subtotal-text {
        font-size: 10px; /* Decrease the font size for the subtitle */
    }
}